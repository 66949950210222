// VERSION 1.0.0

// For Empty Fields Validation
const isRequired = value => value === '' ? false : true;

// Email Validation
const isEmailValid = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

const isNumberOfNetsValid = numb => (numb !== '0') ? true : false;

// Enabling the Submit button
const enableSubmitButton = () => {
    let customerName = document.querySelector('[name="sgform-name"]'),
        customerEmail = document.querySelector('[name="sgform-email"]'),
        customerCompany = document.querySelector('[name="sgform-company"]'),
        customerLocation = document.querySelector('[name="sgform-location"]'),
        customerNumberOfNets = document.querySelector('[name="sgform-numbnets"]'),
        customerMessage = document.querySelector('[name="sgform-message"]'),
        submitButton = document.querySelector('.safeguardprimary-buttons.fleet-form');

    if (isRequired(customerName.value) && isRequired(customerEmail.value) && isRequired(customerCompany.value) && isRequired(customerLocation.value) && isRequired(customerNumberOfNets.value) && isRequired(customerMessage.value) && isEmailValid(customerEmail.value) && isNumberOfNetsValid(customerNumberOfNets.value)) {
        submitButton.disabled = false;
    } else {
        submitButton.disabled = true;
    }
}

export const fleetFormStateHandle = (e) => {
    const errorBox = document.querySelector('.safeguardprimary-forms__error-message');

    if (!isRequired(e.target.value)) {
        e.target.style.border = '1px solid #D23D38';
        errorBox.classList.remove('hide');
    } else {
        if (e.target.name == 'sgform-email') {
            if (!isEmailValid(e.target.value)) {
                e.target.style.border = '1px solid #D23D38';
                errorBox.classList.remove('hide');
            } else {
                e.target.style.border = '1px solid #3B3B3B';
                errorBox.classList.add('hide');
            }
        } else {
            e.target.style.border = '1px solid #3B3B3B';
        }
    }
    enableSubmitButton();
}

// Number of Nets - Decrease and Increase Field
export const numberOfNets = (action) => {
    let numbnets = Number(document.getElementById('field-numbnets').value);

    if (action == 'increase') {
        document.getElementById('field-numbnets').value = numbnets + 1;
    } else {
        if (numbnets !== 0) {
            document.getElementById('field-numbnets').value = numbnets - 1;
        }
    }

    enableSubmitButton();
}