// Version 1.0.0

import { connectingData, settingAllAttributes, whatServer, shuffleArray, customElement, whatTheme } from "./global";

const getReviews = async (id = '/?per_page=100') => {
    try {
        let results = await connectingData(whatServer() + '/wp-json/wp/v2/safeguard-reviews' + id, 'GET');
        let jsonData = JSON.parse(results.responseText);

        return jsonData;
    }
    catch (err) {
        return err;
    }
}

export const showCustomerReviews = () => {
    const container = document.querySelector('.safeguard-home-customer-reviews__list');
    container.innerHTML = '';
    let numReviews = 0;

    if (window.innerWidth < 768) {
        numReviews = 4;
    } else if (window.innerWidth > 767 && window.innerWidth < 992) {
        numReviews = 8;
    } else {
        numReviews = 10;
    }

    getReviews().then((reviews) => {
        // sorting randomly 
        reviews = shuffleArray(reviews);

        for (let index = 0; index < numReviews; index++) {
            const messageBox = customElement('article', 'safeguard-home-customer-review grid-item', ''),
                messageInfo = customElement('div', 'safeguard-home-customer-review__info', ''),
                messageStars = customElement('div', 'safeguard-home-customer-review__stars', ''),
                messageIcon = customElement('img', 'safeguard-home-customer-review__logo-icon', '');

            settingAllAttributes(messageIcon, [
                ['src', whatServer() + '/wp-content/themes/' + whatTheme() + '/dist/assets/backend-images/safeguard-logo-icon.svg'],
                ['alt', 'Safeguard Logo Icon'],
                ['width', '30'],
                ['height', 'auto'],
            ]);

            messageInfo.append(
                messageIcon,
                customElement('p', 'safeguard-home-customer-review__name', reviews[index].title.rendered)
            );

            for (let star = 0; star < reviews[index].acf['safeguard-num-star-reviews']; star++) {
                messageStars.append(customElement('i', 'fa-solid fa-star', ''));
            }

            var message = reviews[index].acf['safeguard-customer-message'];
            var firstPart = '';
            var secPart = '';

            if (message.length > 150) {
                firstPart = message.substring(0, 150);
                firstPart = firstPart.substring(0, Math.min(firstPart.length, firstPart.lastIndexOf(' ')));

                secPart = message.substring(firstPart.length, message.length);

                message = '"' + firstPart + ' <span class="more-text hide">' + secPart + '</span>' + '"' + '&nbsp;&nbsp;<span type="button" class="safeguard-home-customer-review__read-more-btn">read more</span>';
            } else {
                message = '"' + message + '"';
            }

            messageBox.append(
                messageStars,
                customElement('p', 'safeguard-home-customer-review__message', message),
                messageInfo
            );

            container.append(messageBox);
        }
    });
}