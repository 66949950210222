// Version 2023.05.23

import "./styles.scss";
import {
    ready,
    addGlobalEventListeners,
    mountTheSplide,
    productCarousel,
    sizingToolButtonSwitch,
    freebieCompetitionCountdown
} from "./app/global";
import { formStateHandle, maxCharLimit } from "./app/form-validation";
import {
    initSizingToolApp,
    showVehicleSizes,
    displayToolBoxRecommendations,
    enableCargoTypeOptions,
    restoreDefaults,
    showNetRecommendations,
    displaySizingToolAppOnlineStores,
} from "./app/sizing-tool";
import { showCustomerReviews } from "./app/customer-reviews";
import { numberOfNets, fleetFormStateHandle } from "./app/fleets-form";

ready(function () {
    // console.log('Running successfully...');

    // AVAILABLE NET SIZES
    if (document.querySelector('.safeguard-net-category-sizes') !== null) {
        mountTheSplide('.splide.available-net-sizes', 'net-sizes');
    }

    // NET CATEGORY GALLERY
    if (document.querySelector('.safeguard-net-category-gallery') !== null) {
        mountTheSplide('.splide.net-category-gallery', 'gallery');
    }

    // NET PRODUCT PREVIEW
    if (document.querySelector('.safeguard-net-information') !== null) {
        productCarousel();
    }

    // OTHER NET SIZES
    if (document.querySelector('.safeguard-page-other-net-sizes') !== null) {
        mountTheSplide('.splide.other-net-sizes', 'net-sizes');
    }

    // CONTACT US
    if (document.querySelector('.safeguard-form.contact-us') !== null) {
        let customerName = document.querySelector('[name="sgform-name"]'),
            customerEmail = document.querySelector('[name="sgform-email"]'),
            customerPostCode = document.querySelector('[name="sgform-postcode"]'),
            customerSubject = document.querySelector('[name="sgform-subject"]'),
            customerMessage = document.querySelector('[name="sgform-message"]'),
            submitButton = document.querySelector('.safeguard-buttons--submit.contact-us');

        customerMessage.setAttribute('maxlength', 550);
        submitButton.disabled = true;

        customerName.addEventListener('input', formStateHandle);
        customerEmail.addEventListener('input', formStateHandle);
        customerPostCode.addEventListener('input', formStateHandle);
        customerSubject.addEventListener('input', formStateHandle);
        customerMessage.addEventListener('input', formStateHandle);
        customerMessage.addEventListener('input', maxCharLimit);
    }

    // CUSTOM CARGO NETS
    if (document.querySelector('.safeguard-custom-cargo-nets') !== null) {
        mountTheSplide('.splide.custom-cargo-nets-gallery', 'gallery');

        let customerName = document.querySelector('[name="sgform-name"]'),
            customerEmail = document.querySelector('[name="sgform-email"]'),
            customerMessage = document.querySelector('[name="sgform-message"]'),
            submitButton = document.querySelector('.safeguard-buttons--submit.custom-net-request');

        customerMessage.setAttribute('maxlength', 550);
        submitButton.disabled = true;

        customerName.addEventListener('input', formStateHandle);
        customerEmail.addEventListener('input', formStateHandle);
        customerMessage.addEventListener('input', formStateHandle);
        customerMessage.addEventListener('input', maxCharLimit);
    }

    // SIZING TOOL
    if (document.querySelector('.sg-sizing-tool') !== null) {
        initSizingToolApp();
    }

    // HOME PAGE
    if (document.querySelector('.safeguardmaster-home-banner-slider') !== null) {
        mountTheSplide('.splide.home-banner-slider', 'gallery');
    }

    if (document.querySelector('.safeguardprimary-home-trusted-by-top-fleets') !== null) {
        mountTheSplide('.splide.trusted-top-fleets', 'trusted-top-fleets');
    }

    if (document.querySelector('.safeguard-home-customer-reviews') !== null) {
        showCustomerReviews();

        // REVIEWS READ MORE
        addGlobalEventListeners('click', ['.safeguard-home-customer-review__read-more-btn'], e => {
            const message = e.target.previousElementSibling;

            if (message.classList.contains('hide')) {
                message.classList.remove('hide');
                e.target.innerHTML = 'read less';
            } else {
                message.classList.add('hide');
                e.target.innerHTML = 'read more';
            }
        });
    }

    // FREEBIE COMPETITION
    if (document.querySelector('.safeguardmaster-page.freebie-competition') !== null) {
        freebieCompetitionCountdown();
    }

    // FLEETS PAGE
    if (document.querySelector('.safeguardprimary-page.safeguard-fleets-page') !== null) {
        addGlobalEventListeners('click', ['.safeguardprimary-forms-fields__numbers-button'], e => {
            numberOfNets(e.target.classList[1]);
        });

        let customerName = document.querySelector('[name="sgform-name"]'),
            customerEmail = document.querySelector('[name="sgform-email"]'),
            customerCompany = document.querySelector('[name="sgform-company"]'),
            customerLocation = document.querySelector('[name="sgform-location"]'),
            customerNumberOfNets = document.querySelector('[name="sgform-numbnets"]'),
            customerMessage = document.querySelector('[name="sgform-message"]'),
            submitButton = document.querySelector('.safeguardprimary-buttons.fleet-form');

        submitButton.disabled = true;

        customerName.addEventListener('input', fleetFormStateHandle);
        customerEmail.addEventListener('input', fleetFormStateHandle);
        customerCompany.addEventListener('input', fleetFormStateHandle);
        customerLocation.addEventListener('input', fleetFormStateHandle);
        customerNumberOfNets.addEventListener('input', fleetFormStateHandle);
        customerMessage.addEventListener('input', fleetFormStateHandle);
    }
});

// EVENTS

addGlobalEventListeners('click', ['.sg-sizing-tool-buttons--vehicle-types'], e => {
    sizingToolButtonSwitch(e.target.getAttribute('data-key'), 'vehicle-types');

    sessionStorage.clear();
    restoreDefaults();

    // Set Vehicle ID and Slug in Session Storage
    sessionStorage.setItem('vehicle-type-id', e.target.getAttribute('data-key'));
    sessionStorage.setItem('vehicle-type-slug', e.target.getAttribute('data-slug'));

    showVehicleSizes();
});

addGlobalEventListeners('click', ['.sg-sizing-tool-buttons--vehicle-sizes'], e => {
    sizingToolButtonSwitch(e.target.getAttribute('data-key'), 'vehicle-sizes');

    // Set Vehicle Size in Session Storage
    sessionStorage.setItem('vehicle-size-id', e.target.getAttribute('data-key'));
    sessionStorage.setItem('vehicle-size-type', e.target.getAttribute('data-type'));
    sessionStorage.setItem('subcat-icon', e.target.getAttribute('data-subcat'));

    restoreDefaults();

    sessionStorage.removeItem('cargo-load-type');

    if (e.target.getAttribute('data-type') == 'true') {
        displayToolBoxRecommendations();
    } else {
        enableCargoTypeOptions();
    }
});

addGlobalEventListeners('click', ['.sg-sizing-tool-buttons--cargo-load'], e => {
    sizingToolButtonSwitch(e.target.getAttribute('data-key'), 'cargo-load');

    // Set Cargo Load Type
    sessionStorage.setItem('cargo-load-type', e.target.classList[2]);
    sessionStorage.setItem('reg-slug', e.target.getAttribute('data-reg'));
    sessionStorage.setItem('dry-slug', e.target.getAttribute('data-dry'));

    showNetRecommendations();
});

addGlobalEventListeners('click', ['.sg-sizing-tool-buttons--net-type-buttons'], e => {
    showNetRecommendations(e.target.getAttribute('data-code'));
    e.target.setAttribute('disabled', '');

    document.querySelectorAll('.sg-sizing-tool-buttons--net-type-buttons').forEach(button => {
        if (button.getAttribute('data-code') !== e.target.getAttribute('data-code')) {
            button.removeAttribute('disabled');
        }
    });
});

addGlobalEventListeners('click', ['.sg-sizing-tool-buttons--results.net-option'], e => {
    document.querySelector('.safeguard-body').style.overflowY = 'hidden';
    document.querySelector('.sg-sizing-tool-app-stores').classList.remove('hide');

    displaySizingToolAppOnlineStores(e.target.getAttribute('data-store').toLowerCase());
});

addGlobalEventListeners('click', ['.sg-sizing-tool-app-stores', '.sg-sizing-tool-app-stores__close-button'], e => {
    document.querySelector('.safeguard-body').style.overflowY = 'visible';
    document.querySelector('.sg-sizing-tool-app-stores').classList.add('hide');
});

// Dropdown Links
addGlobalEventListeners('click', ['.safeguard-main-menu-links__item-dropdown-label'], e => {
    var allDropdownMenu = ['nets-tarps', 'resources', 'about'];

    if (document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + e.target.getAttribute('data-dropdown')).classList.contains('hide')) {
        e.target.classList.add('active');
        document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + e.target.getAttribute('data-dropdown')).classList.remove('hide');
        document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + e.target.getAttribute('data-dropdown')).classList.add('active');
        e.target.children[0].style.transform = 'rotate(180deg)';

    } else {
        e.target.classList.remove('active');
        document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + e.target.getAttribute('data-dropdown')).classList.add('hide');
        document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + e.target.getAttribute('data-dropdown')).classList.remove('active');
        e.target.children[0].style.transform = 'rotate(360deg)';
    }

    if (!document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.contains('hide')) {
        document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.add('hide');

        if (document.querySelector('.safeguard-buttons--where-to-buy').classList.contains('active')) {
            document.querySelector('.safeguard-buttons--where-to-buy').classList.remove('active');
        }

        if (document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.contains('active')) {
            document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.remove('active');
        }
    }

    allDropdownMenu.forEach(menu => {
        if (e.target.getAttribute('data-dropdown') !== menu) {
            if (!document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.contains('hide')) {
                document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.add('hide');
                document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.remove('active');

                document.querySelectorAll('.safeguard-main-menu-links__item-dropdown-label').forEach(menutitle => {
                    if (menutitle.getAttribute('data-dropdown') == menu) {
                        menutitle.classList.remove('active');
                        menutitle.children[0].style.transform = 'rotate(360deg)';
                    }
                });
            }
        }
    });
});

addGlobalEventListeners('click', ['.safeguard-buttons--where-to-buy', '.safeguard-button-icons--where-to-buy-header'], e => {
    var allDropdownMenu = ['nets-tarps', 'resources', 'about'];

    if (document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.contains('hide')) {
        document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.remove('hide');
        e.target.classList.add('active');
    } else {
        document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.add('hide');
        e.target.classList.remove('active');
    }

    allDropdownMenu.forEach(menu => {
        if (!document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.contains('hide')) {
            document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.add('hide');
            document.querySelector('.safeguard-main-menu-links__item-dropdown-links.' + menu).classList.remove('active');

            document.querySelectorAll('.safeguard-main-menu-links__item-dropdown-label').forEach(menutitle => {
                if (menutitle.getAttribute('data-dropdown') == menu) {
                    menutitle.classList.remove('active');
                    menutitle.children[0].style.transform = 'rotate(360deg)';
                }
            });
        }
    });
});

addGlobalEventListeners('click', ['.safeguard-button-icons--hamb-menu'], e => {
    if (document.querySelector('.safeguard-header-main-menu__mobile-menu').classList.contains('hide')) {
        document.querySelector('.safeguard-header-main-menu__mobile-menu').classList.remove('hide');
        document.querySelector('.menu-icon').classList.add('hide');
        document.querySelector('.close-menu-icon').classList.remove('hide');
    } else {
        document.querySelector('.safeguard-header-main-menu__mobile-menu').classList.add('active', 'hide');
        document.querySelector('.menu-icon').classList.remove('hide');
        document.querySelector('.close-menu-icon').classList.add('hide');
    }

    if (!document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.contains('hide')) {
        document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.add('hide');

        if (document.querySelector('.safeguard-buttons--where-to-buy').classList.contains('active')) {
            document.querySelector('.safeguard-buttons--where-to-buy').classList.remove('active');
        }

        if (document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.contains('active')) {
            document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.remove('active');
        }
    }
})

addGlobalEventListeners('click', ['.safeguard-header-main-menu__mobile-menu-link-label'], e => {
    var allSubMenus = ['nets-range', 'resources', 'about'];

    if (document.querySelector('.safeguard-header-main-menu__mobile-submenu.' + e.target.getAttribute('data-menu')).classList.contains('hide')) {
        e.target.classList.add('active');
        document.querySelector('.safeguard-header-main-menu__mobile-submenu.' + e.target.getAttribute('data-menu')).classList.remove('hide');
        e.target.children[0].style.transform = 'rotate(180deg)';
    } else {
        e.target.classList.remove('active');
        document.querySelector('.safeguard-header-main-menu__mobile-submenu.' + e.target.getAttribute('data-menu')).classList.add('hide');
        e.target.children[0].style.transform = 'rotate(360deg)';
    }

    allSubMenus.forEach(menu => {
        if (e.target.getAttribute('data-menu') !== menu) {
            if (!document.querySelector('.safeguard-header-main-menu__mobile-submenu.' + menu).classList.contains('hide')) {
                document.querySelector('.safeguard-header-main-menu__mobile-submenu.' + menu).classList.add('hide');

                document.querySelectorAll('.safeguard-header-main-menu__mobile-menu-link-label').forEach(menuLabel => {
                    if (menuLabel.getAttribute('data-menu') == menu) {
                        menuLabel.classList.remove('active');
                        menuLabel.children[0].style.transform = 'rotate(360deg)';
                    }
                })
            }
        }
    })
});

document.addEventListener('click', e => {
    if (!document.querySelector('.safeguard-header-main-menu__mobile-menu').classList.contains('hide')) {
        if (!e.target.closest('.safeguard-button-icons--hamb-menu') && e.target !== document.querySelector('.safeguard-header-main-menu__mobile-menu-container')) {
            if (!e.target.closest('.safeguard-header-main-menu__mobile-menu-link-label') && e.target !== document.querySelector('.safeguard-header-main-menu__mobile-submenu')) {
                document.querySelector('.safeguard-header-main-menu__mobile-menu').classList.add('active', 'hide');
                document.querySelector('.menu-icon').classList.remove('hide');
                document.querySelector('.close-menu-icon').classList.add('hide');
            }
        }
    }

    if (document.querySelector('.safeguard-main-menu-links__item-dropdown-links.active') !== null) {
        const dropdownContainer = document.querySelector('.safeguard-main-menu-links__item-dropdown-links.active'),
            dropDownLabel = document.querySelector('.safeguard-main-menu-links__item-dropdown-label.active');

        if (!e.target.closest('.safeguard-main-menu-links__item-dropdown-label.active') && e.target !== document.querySelector('.safeguard-main-menu-links__item-dropdown-links-mid-container')) {
            dropdownContainer.classList.remove('active');
            dropdownContainer.classList.add('hide');
            dropDownLabel.classList.remove('active');
            dropDownLabel.children[0].style.transform = 'rotate(360deg)';
        }
    }

    if (!document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.contains('hide')) {
        if (!e.target.closest('.safeguard-buttons--where-to-buy') && !e.target.closest('.safeguard-button-icons--where-to-buy-header') && e.target !== document.querySelector('.safeguard-header-main-menu__where-to-buy-links')) {
            document.querySelector('.safeguard-header-main-menu__where-to-buy-section').classList.add('hide');
            if (document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.contains('active')) {
                document.querySelector('.safeguard-button-icons--where-to-buy-header').classList.remove('active');
            }

            if (document.querySelector('.safeguard-buttons--where-to-buy').classList.contains('active')) {
                document.querySelector('.safeguard-buttons--where-to-buy').classList.remove('active');
            }
        }
    }
});

// Sticky Navigation 
window.addEventListener('scroll', function () {
    const header = document.querySelector('.safeguard-header-main-menu');
    let windowSize = window.innerWidth;

    if (windowSize > 1119) {
        header.classList.toggle("sticky", window.scrollY > 0);
    }
});

// Product Net Where To Buy - Online Popup
addGlobalEventListeners('click', ['.safeguard-buttons--buy-now.net-details'], e => {
    document.querySelector('.safeguard-body').style.overflowY = 'hidden';
    document.querySelector('.safeguard-online-stores').classList.remove('hide');
});

addGlobalEventListeners('click', ['.safeguard-online-stores', '.safeguard-online-stores__close-button'], e => {
    document.querySelector('.safeguard-body').style.overflowY = 'visible';
    document.querySelector('.safeguard-online-stores').classList.add('hide');
});